import {
	AvailableTimeSlot,
	BranchItem,
	CustomerAppointmentAttendeeItem,
	CustomerAppointmentStaffPreferenceItem,
	InternalUser,
	InternalUserItem,
	LanguagePreferenceItem,
} from '../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';

export interface ScheduleAppointmentSideBarContent {
	contentGUID: string;
	title: string;
	subTitle: string;
	extraContent?: string;
}
export interface ScheduleAppointmentModel {
	id: string;
	title: string;
	content: ScheduleAppointmentSideBarContent[];	
	category?: AppointmentServiceCategories;
	service?: AppointmentService;
	meetingMethod?: AppointmentMeetingMethod;
	staffDetails?: AppointmentStaffDetails;
	meetingDetails?: AppointmentMeetingDetails;
	type: ScheduleAppointmentStage;
	customerDetails?: ScheduleAppointmentCustomerDetails;
}

export interface ScheduleAppointmentCustomerDetails {
	customerAppointmentAttendeeGUID?: string;
	firstName?: string;
	lastName?: string;
	email?: string;
	mobileNumber?: string;
	countryCode?: string;
	additionalDetails?: string;
	receiveTextNotification?: boolean;
	additionalAttendee?: ScheduleAppointmentCustomerDetails;
}
export interface AppointmentStaffDetails {
	internalUser?: InternalUserItem;
	meetingLocation?: BranchItem;
	languagePreference?: LanguagePreferenceItem;
	staffPreference?: CustomerAppointmentStaffPreferenceItem;
}
export interface AppointmentServiceCategories {
	serviceCategoryGUID: string;
	title: string;
	systemCode: string;
	appointmentService: AppointmentService[];
}
export interface AppointmentService {
	serviceGUID: string | null;
	serviceCategoryGUID: string | null;
	title: string;
	systemCode: string;
	duration: string;
	description: string;
}
export interface AppointmentMeetingMethod {
	id: string;
	meetingMethodType: AppointmentMeetingMethodType;
	canSkipLocation?: boolean;
}
export interface AppointmentMeetingMethodType {
	id: string;
	serviceGUID: string;
	title: string;
	description: string;
	systemCode: string;
	iconUrl?: string;
}

export interface AppointmentMeetingDetails {
	selectedDate: string;
	timeZone: string;
	timeSlot: AvailableTimeSlot;
	timeZoneAbbreviation: string;
	startDateTime: string;
	endDateTime: string;
}

export class Tooltip {
	isShown?: boolean;

	text?: string;
}
export class Marker {
	location: unknown;
	onClick?: any;
	tooltip?: Tooltip;
}

export type APIKey = {
	bing?: string;

	google?: string;

	googleStatic?: string;
};

const markerUrl = 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/maps/map-marker.png';

export interface TimeZoneItem {
	id: string;
	label: string;
}

export interface TimeZoneModel {
	countrySystemCode?: string;
	timeZone: string;
	countryGUID?: string;
	timeZoneAbbreviation: string;
}

export enum CustomerAppointmentStaffPreferenceType {
	Anyone_available = 'Anyone_available',
	A_specific_staff_member = 'A_specific_staff_member',
}

export enum ScheduleAppointmentStage {
	SERVICE = 'SERVICE',
	MEETING_METHOD = 'MEETING_METHOD',
	STAFF_DETAILS = 'STAFF_DETAILS',
	MEETING_DETAILS = 'MEETING_DETAILS',
	YOUR_DETAILS = 'YOUR_DETAILS',
}

export enum CustomerAppointmentMeetingPreferenceType {
	IN_PERSON = 'In_Person',
	PHONE = 'Phone',
	VIRTUAL = 'Virtual',
}
export enum CalendarEventStatus {
	FREE = 'free',
	TENTATIVE = 'tentative',
	BUSY = 'busy',
	OUT_OF_OFFICE = 'oof',
	WORKING_ELSE_WHERE = 'workingElsewhere',
	UNKNOWN = 'unknown',
}
export interface UpdateCustomerAppointmentModel {
	meetingTypeGUID: string;
	customerAppointmentGUID: string;
	serviceGUID: string;
	startDateTimeGMT: string;
	endDateTime: string;
	meetingTypeLabel: string;
	meetingTypeSystemCode: string;
	defaultDuration: number;
	internalUserGUID: string;
	branchGUID: string | undefined;
	attendee: CustomerAppointmentAttendeeItem | undefined;
	meetingType: string;
}

export interface UserInfoItem {
	internalUserGUID: string;
	userEmail: string;
}

export interface AppointmentEvent {
	startDateTime?: string;
	endDateTime?: string;
	internalUserGUID?: string;
}

export enum AppointmentMessageType {
	NEW_APPOINTMENT = 'NEW_APPOINTMENT',
	CANCEL_APPOINTMENT = 'CANCEL_APPOINTMENT',
	RE_SCHEDULE_AND_UPDATE_MEETING_TYPE_APPOINTMENT = 'RE_SCHEDULE_AND_UPDATE_MEETING_TYPE_APPOINTMENT',
}

export enum HelperText {
	SMSCheckBoxMessage = 'By checking this box you consent to TRB sending you messages regarding updates to your appointment. Standard messaging rates may apply.',
}
export enum AppointmentOTPVerificationAction {
	RESEND = 'RESEND',
	VERIFY_AND_SCHEDULE = 'VERIFY_AND_SCHEDULE',
	CLOSE = 'CLOSE',
}
export interface AppointmentOTPVerification {
	otpText: string;
	modalAction: AppointmentOTPVerificationAction | null;
	errorMessage: string;
	verificationMode: OTPVerificationMode | null;
}

export enum OTPVerificationMode {
	CALL = 'call',
	SMS = 'sms',
	email = 'email',
}
